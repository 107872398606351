#root {
    margin-bottom: 50px !important;
    padding-bottom: 50px !important;
}

body {
    background-color: #1D2329;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Titillium Web;
}

body::-webkit-scrollbar {
    display: none;
}

.fixtures-content-col::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #6f6f6f;
}
.fixtures-content-col::-webkit-scrollbar-corner {
    background: #6f6f6f;
}

.fixtures-content-right::-webkit-scrollbar{
    background-color: #6f6f6f;
}

.fixtures-content-right::-webkit-scrollbar-thumb{
    background-color: #4B4B4B;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebet-container {
    border-radius: 20px;
    border: 2px solid #337483;
    background: #283038;
}

.sidebet-title {
    color: #337483;
    font-family: Titillium Web;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-shadow: 0 3px 3px #16191C;
}

.sidebet-header div {
    padding-left: 2%;
    padding-right: 2%;
}

.dropping .sidebet-header div {
    padding-left: 1%;
    padding-right: 1%;
}

.sidebet-line {
    border-top: 2px solid #4B4B4B;
    margin: 2% 5%;
    opacity: .5 !important;
}

.container {
    padding-top: 2%;
}

.ant-select-selector {
    background-color: #16191C !important;
    border: 1px solid #4B4B4B !important;
    border-radius: 10px !important;
    color: rgba(255, 255, 255, 0.7) !important;
    padding-left: 27px !important;
}

.dropping .ant-select-selector {
    background-color: #16191C !important;
    border: 1px solid #4B4B4B !important;
    border-radius: 10px !important;
    color: rgba(255, 255, 255, 0.7) !important;
    padding-left: 35px !important;
}

.fixtures-container .ant-select-selector {
    background-color: #283038 !important;
    border: 1px solid #424242 !important;
    border-radius: 10px !important;
    color: rgba(255, 255, 255, 0.7) !important;
    padding-left: 27px !important;
}

.dropping .ant-select-selection-placeholder {
    padding-left: 24px !important;
}

.ant-select-selection-overflow-item {
    padding: 0 !important;
}

.sidebet-nav-item:hover {
    color: #3c89e8;
    border-color: #3c89e8;
}

.sidebet-nav-item {
    background-color: #16191C !important;
    border-radius: 10px !important;
    height: 45px;
    float: right;
    margin: 2px;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 40px;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.85);
    border: 1px solid #424242;
    box-shadow: 0 2px 0 rgba(255, 255, 255, 0.04);
    word-spacing: 0px;
}

.sidebet-button {
    background-color: #16191C !important;
    border-radius: 10px !important;
    height: 45px;
    float: right;
    margin: 2px;
    padding-left: 15px;
    padding-right: 15px;
}

.sidebet-button-small {
    background-color: #283038;
    font-size: 12px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 24px !important;
    border: none !important;
}

.sidebet-button-copy {
    background-color: #6f6f6f;
    padding-top: 0 !important;
    height: 22px;
    margin-right: 2px !important;
    margin-bottom: 2px !important;
    border-color: #606060;
}

.fixtures-button {
    background-color: #1d2024 !important;
    border-radius: 10px !important;
    padding-top: 0 !important;
    height: 36px;
    float: right;
}

.dropping-button {
    background-color: #16191C !important;
    border-radius: 10px !important;
    height: 40px;
    float: right;
}

.dropping .sidebet-button {
    background-color: #16191C !important;
    border-radius: 10px !important;
    height: 45px;
    width: auto;
}

.sidebet-export-button {
    background-color: #16191C !important;
    border-radius: 0 !important;
    width: 120px;
    height: 32px;
    margin-top: -5px;
    margin-bottom: 1rem !important;
}

.sidebet-icon {
    position: absolute;
    z-index: 1;
    margin-left: 13px;
    margin-top: 8px;
    pointer-events: none;
}

.fixtures-header .sidebet-icon {
    position: absolute;
    z-index: 1;
    margin-top: 8px;
    pointer-events: none;
    opacity: 0.5;
}

.fixtures-header .ant-input {
    padding-left: 35px !important;
}

.dropping-bookmakers .sidebet-icon {
    position: absolute;
    z-index: 1;
    margin-left: 16px;
    margin-top: 8px;
    pointer-events: none;
}

.ant-select-selection-search {
    inset-inline-start: 27px !important;
}

.dropping .ant-select-selection-search {
    inset-inline-start: -10px !important;
}

.fixtures-container .ant-select-selection-search {
    inset-inline-start: 35px !important;
}

.fixtures-container .ant-select-selection-placeholder {
    padding-left: 10px !important;
}

.dropping .ant-input-number {
    background-color: #16191C !important;
    border: 1px solid #4B4B4B !important;
    border-radius: 10px !important;
    color: rgba(255, 255, 255, 0.7) !important;
    margin-left: 5px !important;
}

.dropping .ant-input {
    background-color: #16191C !important;
    border: 1px solid #4B4B4B !important;
    border-radius: 10px !important;
    color: rgba(255, 255, 255, 0.7) !important;
    margin-left: 5px !important;
    padding-left: 30px !important;
}

.ant-checkbox-inner {
    background-color: #16191C !important;
}

.dropping-filter div {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.dropping-filter {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.dropping p {
    margin-bottom: 0;
}

.ant-table {
    font-family: Titillium Web !important;
}

.ant-table-cell {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.no-selection {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ant-tree-select-dropdown {
    width: 300px !important;
}

.odds-table {
    border: solid 1px #5d6364;
    box-shadow: -1px 1px 1px #16191C;
    border-radius: 0 !important;
    border-collapse: collapse !important;
}

.odds-light-bg {
    background-color: #313840;
}

.odds-dark-bg {
    background-color: #1d2329;
}

.fixtures-container {
    margin: auto;
}

.fixtures-header {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border: 2px solid #262626;
    background: #384048;
    height: 125px;
}

.fixtures-content {
    border-bottom-right-radius: 15px;
    border: 2px solid #262626;
    border-top: none;
    background: #8b8b8b;
    min-height: 475px;
    height: 64vh;
}

.fixtures-content-background {
    background: #8b8b8b;
}

.fixtures-content-col {
    max-height: 48vh;
}

.fixtures-sidebar {
    position: relative;
    border-bottom-left-radius: 15px;
    border: 2px solid #262626;
    border-right: none;
    border-top: none;
    background: #6f6f6f;
    min-height: 475px;
    height: 64vh;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 14px;
    color: #111111;
    padding: 0;
}

.fixtures-sidebar::-webkit-scrollbar {
    display: none;
}

.fixtures-content::-webkit-scrollbar {
    display: none;
}

.fixtures-sidebar-item {
    width: 120%;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    background: #626262;
}

.fixtures-sidebar-item.selected {
    padding-top: 0.30rem;
    padding-bottom: 0.30rem;
    background: #555555;
}

.fixtures-sidebar-item.category {
    margin-left: 5px;
}

.fixtures-sidebar-item.tournament {
    margin-left: 10px;
}

.fixtures-sidebar-item-count {
    position: absolute;
    left: 35px;
}

.border-margin-report {
    border: 2px solid #337483;
}

.fixtures-content-header {
    z-index: 100;
    position: sticky;
    background: #8b8b8b;
    border-radius: 15px;
    padding-top: 1.5rem;
    padding-bottom: 0.25rem;
    top: 0;
    left: 0;
}

.fixtures-sidebar-header {
    z-index: 100;
    position: sticky;
    background: #6f6f6f;
    padding: 0.5rem 0.5rem;
    top: 0;
}

.fixtures-event {
    font-size: 14px;
    padding: 0.1rem;
}

.fixtures-event-header {
    background-color: #6f6f6f;
    text-align: center;
    font-size: 12px;
    padding: 0.1rem;
    font-weight: bold;
}

.fixtures-bookmakers-cell {
    overflow: hidden;
    height: 1.75rem;
    width: 4.5rem;
}

.fixtures-cell {
    height: 1.75rem;
}

.scrollable-horizontal {
    overflow-x: auto;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.fixtures-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1050;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.fixtures-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 1051;
}

.fixtures-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1040;
}

.fixtures-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}